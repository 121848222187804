import React from "react";
import "./Gallery.css";
import Images from "./Images";

export default function Photogallery() {

  let siteimages = [
    {
      src:"./Photos/Photogallery/site1-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/Cottage3-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site5-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site6-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site9-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site15-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site17-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site18-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site19-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site20-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site21-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site23-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site24-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/site25-logo.webp",
      alt:"Tikona",
    },
  ];

  let activityimages = [
    {
      src:"./Photos/Photogallery/bird1-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/bird2-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/bird4-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/fishing1-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/fishing2-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/zipline1.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/obstacle.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/activity1-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/activity2-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/boat1.webp",
      alt:"Tikona",
    },
  ];

  let seasonalimages = [
    {
      src:"./Photos/Photogallery/christ2-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/christ3-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/christ6-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/christ11-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/christ12-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/light1-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/light2-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/ricefarming1-logo.webp",
      alt:"Tikona",
    },
    {
      src:"./Photos/Photogallery/ricefarming2-logo.webp",
      alt:"Tikona",
    },
  ];

  return (
    <div className="photogallery">
      <div className="aboutContainer">
        <h2 id="head1">Gallery</h2>
      </div>
      <div className="Gallery">
        <div className="top"></div>
        <br />
        <div className="FacButtons">
          <a href="#gallery" className="btn btn-primary custom-button">SITE PHOTOS</a>
          <a href="#gallery1" className="btn btn-primary custom-button">ACTIVITY</a>
          <a href="#gallery2" className="btn btn-primary custom-button">SEASONAL ACTIVITIES</a>
        </div>
        <div className="gall">
          <div div id="gallery" className="gallery">
            <div className="Photo-head">
              <p id="head2">TIKONA FARM</p>
              <p id="Tikona-foot">Be the Eyewitness of the Tikona Beauty</p>
            </div>
            <div className="row">
              {
                siteimages.map((image, index) => {
                  return <Images src={image.src} alt={image.alt} key={index} />
                })
              }
              <div className="FacButtons">
                <a href="#top" className="btn btn-primary custom-button">Back To Top</a>
              </div>
            </div>
          </div>
        </div>
        <div id="gallery1" className="gallery1">
          <div className="Photo-head">
            <p id="head2">ACTIVITY</p>
            <p id="Tikona-foot">We Will Make Your Childhood Memories Alive</p>
          </div>
          <div className="row">
            {
              activityimages.map((image, index) => {
                return <Images src={image.src} alt={image.alt} key={index} />
              })
            }
            <div className="FacButtons">
              <a href="#top" className="btn btn-primary custom-button">Back To Top</a>
            </div>
          </div>
        </div>
        <div id="gallery2" className="gallery2">
          <div className="Photo-head">
            <p id="head2">SEASONAL ACTIVITIES</p>
            <p id="Tikona-foot">Every Year Santa Visits The Tikona Farm for Christmas Celebration</p>
          </div>
          <div className="row">
            {
              seasonalimages.map((image, index) => {
              return <Images src={image.src} alt={image.alt} key={index} />
              })
            }
            <div className="FacButtons">
              <a href="#top" className="btn btn-primary custom-button">Back To Top</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}