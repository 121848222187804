import '../../App.css';
import Homeintro from './Homeintro';
import Offers from './Offers';
import Carousel from './Carousel';
import Section from './Section';

export default function Homepage() {
  return (
    <div className='App'>
      <Carousel />
      <Homeintro />
      <Offers />
      <Section />
    </div>
  );
}