import React from 'react';
import { Routes, Route  } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Homepage from './Components/Home/Homepage';
import Contacts from './Components/Contact/Contact';
import About from './Components/About/About';
import Facilities from './Components/Facilities/Facilities';
import Gallery from './Components/Gallery/Gallery';
import Packages from './Components/Packages/Packages';
import Tourism from './Components/Tourism/Tourism';

function App() {
  return (
  <div className='App'>
      <Navbar />
        <Routes>
          <Route exact path="/" element={<Homepage/>} />
          <Route path="/contact" element={<Contacts/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/facilities" element={<Facilities/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/packages" element={<Packages/>} />
          <Route path="/tourism" element={<Tourism/>} />
        </Routes>
        <Footer />
  </div>
  );
}

export default App;
