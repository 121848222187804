const Images = (props) => {
    return<>
        <div className="col-sm">
            <div className="card" style={{ width: "18rem", height:"18rem" }}>
                <img className="card-img-top" src={props.src} alt={props.alt} />
                <div className="card-body">
                    <h5 className="card-title">{props.name}</h5>
                </div>
            </div>
        </div>
    </>
}

export default Images