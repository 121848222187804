import React, { useState, useEffect, useMemo } from "react";

const Carousel = () => {
    const images = useMemo(
        () => [
            "./Photos/Tikona1-logo.webp",
            "./Photos/Tikona2-logo.webp",
            "./Photos/Umbrella-logo.webp",
            "./Photos/Foreigner2-logo.webp",
            "./Photos/Tikona3-logo.webp",
            "./Photos/Tikona4-logo.webp",
            "./Photos/Tikona5.webp",
            "./Photos/Tikona6.webp",
        ],
        []
    );

  const texts = [
    "Tikona Farms: Experience the Perfect Blend of Family Time, Camping, and Agro Tourism",
    "Unforgettable Lake View Experience: Discover the Best of Nature's Serenity",
    "Joyful kiddos blooming amidst nature's embrace at Tikona Farms!",
    "Discover India's cultural essence at Tikona Farms",
    "Immerse Yourself in the Beauty of Hadshi Lake: Surrounded on Three Sides for a Picturesque Experience",
    "Experience the Glamour of Celebrity Visits at Tikona Farms: A Destination that Attracts Stars",
    "Embrace serenity and awaken your senses at Sunrise Tikona Farms",
    "Unforgettable Lakeside Family adventure with Spectacular Views of Tikona Fort",
  ];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Preload images
    images.forEach((img) => {
      const image = new Image();
      image.src = img;
    });
    
    let intervalId;
    intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    
    return () => clearInterval(intervalId);
  }, [images]);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="carousel-container">
      <button onClick={handlePrev} className="prev-button">
        &#10094;
      </button>
      {images.map((img, i) => (
        <div
          key={i}
          className={`carousel-image ${
            i === index
              ? "active"
              : i === index - 1 || (index === 0 && i === images.length - 1)
              ? "prev"
              : "next"
          }`}
        >
          <img style={{ border: "2px solid grey" }} src={img} alt={`carousel-${i}`} />
          <div className="image-text">{texts[i]}</div>
        </div>
      ))}
      <button onClick={handleNext} className="next-button">
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;