import React, { useRef } from "react";
import "./Packages.css";
import { useScrollAnimation } from "../useScrollAnimation";

export default function Packages() {

  const paraRef1 = useRef();
  const paraRef2 = useRef();
  const showText = useScrollAnimation([paraRef1, paraRef2]);

  return (
    <>
      <div className="aboutContainer">
        <h2 id="head1">Explore Exciting Packages at Tikona Farms: Your Ultimate Getaway</h2>
      </div>
      <div className="Packages-head"></div>
      <div className="Packages-Info">
      <p id="para" ref={paraRef1} className={showText[0] ? "animated" : ""} style={{"marginTop":"10px"}}>
          Discover the Perfect Camping Destination near Pune, Mumbai, Lonavala, and Pawana at Tikona Farms<br />
          Tikona Farms offers a splendid opportunity to indulge in camping amidst the picturesque beauty of Hadshi Lake and lush green foliage.<br /><br />
          Located a mere 39 km from Pune, 120 km from Mumbai, and just 26 km from Lonavala, Tikona Farms Campsite is nestled amidst breathtaking mountains, with the majestic Tikona Fort as its backdrop. This enchanting property stretches along the banks of Lake Hadshi, providing an idyllic setting for your camping adventure.<br /><br />
          Guests often describe "Tikona Farms" as synonymous with nature, as it is surrounded by serene lake waters, mango trees, and flourishing farms. Immerse yourself in the beauty of the surroundings and rejuvenate your senses.
        </p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div className="card" style={{ width: "19rem", height:"54rem" }}>
              <img className="card-img-top" src="./Photos/IMG_1136-logo.webp" alt="Day Return Outing" />
              <div className="card-body">
                <h5 className="card-title">
                  <p id="packageTitle">DAY RETURN OUTING</p>
                  <span id="time">TIMING: 9:00 AM to 5:00 PM </span>
                  <br />
                  <br />
                  <table>
                    <tr>
                      <th>Type</th>
                      <th>Price</th>
                    </tr>
                    <tr>
                      <td>Adult</td>
                      <td>INR 1000/-<b/>
                      <p className="font-14">per person</p>
                      </td>
                    </tr>
                    <tr>
                      <td>Kids (4-10 Year)</td>
                      <td>INR 600/-<b/>
                      <p className="font-14">per person</p>
                      </td>
                    </tr>
                  </table>
                </h5>
                <br />
                <p className="card-text">
                <span id="time" className="font-18 1">Includes : </span>
                  <br />
                  Breakfast & Tea/Coffee<br />
                  Lunch (Veg/Non-Veg)<br />
                  Evening Snacks & Tea<br/>
                  [Indoor & Outdoor activities] <br/>
                  <i>Boating is not Included</i>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card" style={{ width: "19rem", height:"54rem" }}>
              <img className="card-img-top" src="./Photos/Camping1-logo.webp" alt="Overnight Lakeside Tent Camping" />
              <div className="card-body">
                <h5 className="card-title">
                <p id="packageTitle">OVERNIGHT LAKESIDE TENT CAMPING</p>
                <span id="time">TIMING: 5:00 PM to 10:00 AM</span>
                  <br />
                  <br />
                  <table>
                    <tr>
                      <th>Type</th>
                      <th>Price</th>
                    </tr>
                    <tr>
                      <td>Adult</td>
                      <td>INR 1600/-<br />
                      <p className="font-14">per person</p>
                      </td>
                    </tr>
                    <tr>
                      <td>Kids (4-10 Year)</td>
                      <td>INR 1000/-<b/>
                      <p className="font-14">per person</p>
                      </td>
                    </tr>
                  </table>
                </h5>
                <br />
                <p className="card-text">
                <span id="time" className="font-18">Includes : </span>
                  <br />
                  Evening Snacks & Tea<br />
                  Stay in Camping Tent<br />
                  Barbecue (200 Gram Each)<br />
                  Dinner (Veg/Non-Veg)<br />
                  Bonfire<br />
                  Next Morning Breakfast & Tea<br/>
                  [Indoor & Outdoor activities] <br/>
                  <i>Boating is not Included</i>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card" style={{ width: "19rem", height:"54rem" }}>
              <img className="card-img-top" src="./Photos/tent1-logo.webp" alt="Lakeside Cottage Stay" />
              <div className="card-body">
                <h5 className="card-title">
                <p id="packageTitle">LAKESIDE COTTAGE STAY</p>
                <span id="time">TIMING: 12:30 PM TO 10:00 AM</span>
                  <br />
                  <br />
                  <table>
                    <tr>
                      <th>Type</th>
                      <th>Price</th>
                    </tr>
                    <tr>
                      <td>Adult</td>
                      <td>INR 2900/-<b/>
                      <p className="font-14">per person</p>
                      </td>
                    </tr>
                    <tr>
                      <td>Kids (4-10 Year)</td>
                      <td>INR 1300/-<b/>
                      <p className="font-14">per person</p>
                      </td>
                    </tr>
                  </table>
                </h5>
                <br />
                <p className="card-text">
                <span id="time" className="font-18">Includes : </span>
                  <br />
                  Lunch (Veg/Non-Veg)<br />
                  Stay in Lakeside Cottage<br />
                  Evening Snacks & Tea<br />
                  Barbecue (Limited)<br />
                  Dinner (Veg/Non-Veg)<br />
                  Next Morning Breakfast & Tea<br/>
                  [Indoor & Outdoor activities] <br/>
                  <i>Boating is not Included</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p id="para" ref={paraRef2} style={{ animation: "none" }} className={showText[1] ? "animated" : ""}>
        Experience a range of thrilling activities in our exclusive packages, except for boating at Tikona Farms.<br />
        Adventure awaits at every turn, ensuring an unforgettable journey.
      </p>
      <div className="button">
        <a href="./contact" className="btn btn-primary custom-button">
          BOOKING DETAILS
        </a>
      </div>
      <br />
      <div id="Refund1" className="col-sm">
          <div className="aboutContainer">
            <h2 id="head2">Additional Extra Activities</h2>
          </div>
            <p>
              <strong>Additional Extra Arragements as per Requirement:</strong>
              <br />
              <br />
              <ul class="bullet-list">
                <li><u>School Trips</u>: Unforgettable adventures await at Tikona Farms!</li>
                <li><u>Children Camps</u>: Adventure, laughter, friendship. Tikona Farms welcomes children!</li>
                <li><u>Engagement Ceremony</u>: Celebrate love's journey at Tikona Farms</li>
                <li><u>Blossoming Joy</u>: Tikona Farms Baby Shower celebrations!</li>
                <li><u>Birthday Celebrations</u>: Unforgettable birthdays come alive at Tikona Farms!</li>
                <li><u>Anniversary Celebrations</u>: Unforgettable moments. Anniversary bliss. Tikona Farms</li>
                <li><u>Family Get-Togethers</u>: Unforgettable memories created at Tikona Farms' reunions</li>
                <li><u>Office Re-Unions</u>: Reconnect. Celebrate. Reminisce. Unite. Office Reunion at Tikona Farms</li>
                <strong>& Many More...</strong>
              </ul>
            </p>
          </div>
      <br />
      <div className="Refund">
        <div className="row">
          <div id="Refund1" className="col-sm">
            <div className="aboutContainer">
              <h2 id="head2">Note</h2>
            </div>
            <p>
              <strong>Important Information for Your Convenience:</strong>
              <br />
              <br />
              <ul style={{"listStylePosition": "outside", "text-align":"left"}}>
                <li>Boating is not owned by Tikona Farms. You will need to pay for boating seperately.</li>
                <li>The rates of the packages mentioned above may vary from 1 Dec - 5 Jan. For detailed information, kindly get in touch with us.</li>
                <li>Sorry, but pets are not allowed.</li>
                <li>Special Group Discount: Enjoy a 10% discount on full camping packages for groups of 20 persons and above.</li>
                <li>Prior booking is compulsary, need to pay 50% amount for confirm booking</li>
                <li>Admission for walk-in guest is purely based on our availability. If we are sold out for particular day then we cannot accept any walk-in entry.</li>
                <li>Packaged drinking water and cold drinks can be purchased from the tuck shop at extra cost.</li>
                <li>Be mindful of other guests. Loud music and noise will not be allowed.</li>
                <li>You may carry your own food and beverages.</li>
              </ul>
            </p>
          </div>

          <div id="Refund1" className="col-sm">
            <div className="aboutContainer">
              <h2 id="head2">Cancellation and Refund policy</h2>
            </div>
            <p>
              <table class="table-fill">
                <thead>
                  <tr>
                    <th>Time Period</th>
                    <th>Refund Amount</th>
                  </tr>
                </thead>
                <tbody class="table-hover">
                  <tr>
                    <td>Before 48 Hours</td>
                    <td>No Refund</td>
                  </tr>
                  <tr>
                    <td>Before 2 To 7 Days</td>
                    <td>50% Refund</td>
                  </tr>
                  <tr>
                    <td>Before 7 To 15 Days</td>
                    <td>80% Refund</td>
                  </tr>
                </tbody>
              </table>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}