import React, { useRef } from "react";
import "./Home.css";
import { useScrollAnimation } from "../useScrollAnimation";

export default function Homeintro() {

  const paraRef = useRef();
  const showText = useScrollAnimation(paraRef);

  return (
    <div className="why">
      <div className="nav-item">
        <h2 id="head2"> Why Tikona Farms?</h2> 
      </div>
      <div className="row">
        <div className="text-container">
        <p id="para" ref={paraRef} className={showText[0] ? "animated" : ""}>
            Escape the Urban Grind: Discover the Serenity of Tikona Farms near Pune City<br /><br />
            Looking for a much-needed respite from the daily grind of city life?<br/>
            <ul style={{"listStylePosition": "outside", "textAlign":"left"}}>
              <li>Tikona Farms offers the perfect retreat near beautiful Hadshi Lake in Mulshi.</li>
              <li>Break free for a weekend and indulge in the tranquility of nature.</li>
              <li>Experience the invigorating freshness of misty mornings, relish in playful water splashes in the pristine lake, and bask in the warmth of a starlit sky while camping.</li>
              <li>Rediscover the simple pleasures of life at Tikona Farms, where relaxation and agro tourism blend seamlessly.</li>
            </ul>
            <br/>
            Situated on the banks of Hadshi Lake and in close proximity to Tikona Fort, Tikona Farms provides an idyllic setting for your escape. Located just 39 km from Pune, this serene destination offers a delightful combination of natural beauty and agricultural charm. Immerse yourself in the soothing ambiance of Hadshi Lake, which also serves as an irrigation source. Experience the essence of tranquility and rejuvenation at Tikona Farms, away from the hustle and bustle of city life.
          </p>
        </div>
        <div className="image-container">
          <img src="./Photos/Tikona.webp" alt="why-tikona-farms" id="why-tikona-farms" width="600px" height="600px" />
        </div>
      </div>
    </div>
  );
}