import React from 'react';

export default function Section () {
    return (
        <div className="learn-more-section">
            <div className="content">
                <div className="text-content">
                    <h2>Other Indoor/Outdoor Activities</h2>
                    <a href="./facilities" className="btn btn-primary custom-button">
                        Learn More
                    </a>
                </div>
                <div className="image-content">
                    <img src="./Photos/others-logo.webp" alt="Others" className="learn-more-image" width="266px" />
                </div>
            </div>
        </div>
    );
}