import React, { useState, useEffect, useMemo } from "react";

const Carousel = () => {
    const images = useMemo(
        () => [
            "./Photos/award-logo.webp",
            "./Photos/Newspaper-Articles.webp",
            "./Photos/Foreigner1-logo.webp",
        ],
        []
    );

  const texts = [
    "We are the Winners of the Best Agro Tourism Award in 2019",
    "We are Featured in Several Newspaper Articles",
    "We have Hosted Guests from All Over the World",
  ];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Preload images
    images.forEach((img) => {
      const image = new Image();
      image.src = img;
    });
    
    let intervalId;
    intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    
    return () => clearInterval(intervalId);
  }, [images]);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="carousel-container">
      <button onClick={handlePrev} className="prev-button">
        &#10094;
      </button>
      {images.map((img, i) => (
        <div
          key={i}
          className={`carousel-image ${
            i === index
              ? "active"
              : i === index - 1 || (index === 0 && i === images.length - 1)
              ? "prev"
              : "next"
          }`}
        >
          <img style={{ border: "2px solid grey" }} src={img} alt={`carousel-${i}`} />
          <div className="image-text">{texts[i]}</div>
        </div>
      ))}
      <button onClick={handleNext} className="next-button">
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;