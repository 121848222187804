import { useEffect, useState, useMemo } from "react";

export function useScrollAnimation(refs, threshold = 0.7) {
  const refArray = useMemo(() => (Array.isArray(refs) ? refs : [refs]), [refs]);
  const [show, setShow] = useState(new Array(refArray.length).fill(false));

  useEffect(() => {
    const handleScrollAnimation = () => {
      const triggerHeight = window.innerHeight * threshold;
      const newShowStates = refArray.map((ref) => {
        if (!ref.current) return false;
        const elementPosition = ref.current.getBoundingClientRect().top;
        return elementPosition < triggerHeight && elementPosition > -ref.current.offsetHeight;
      });
      setShow(newShowStates);
    };

    window.addEventListener("scroll", handleScrollAnimation);

    return () => {
      window.removeEventListener("scroll", handleScrollAnimation);
    };
  }, [refArray, threshold]);

  return show;
}