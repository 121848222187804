import React, { useRef } from "react";
import "./Contact.css";

export default function Contacts() {

  const showText = useRef();

  return (
    <div className="contact-container">
      <div className="contact-head">
          <h2 id="head1">Contact Us for Assistance</h2>
          <br /><br />
          <p id="para" style={{"backgroundColor": "rgb(240, 246, 246)"}} className={showText ? "animated" : ""}>
            We value your feedback and are here to assist you. Let us know your requirements, and we'll do our best to help you.<br />
            Reach out to us at <strong><a className="contact-link" href="mailto:tikonafarms@gmail.com">tikonafarms@gmail.com</a></strong> and share your inquiries or concerns.<br />
            We look forward to hearing from you and providing the support you need.
          </p>
      </div>

      <div className="contact-col">
        <div className="contact-section">
          <h2 id="head2">For Booking Contact</h2>
          <br />
          <div className="contact-content">
            <h5 className="contact-info-heading">Contact Information:</h5>
            <p>
              Tikona Farms<br />
              At Post - Kashig, Taluka Mulshi,<br />
              Paud-Pawana Road,<br />
              Hadashi Lake, Vikasvadi,<br />
              Pune - 412108<br />
              Mobile: (+91) 9823407700
            </p>
          </div>

          <div className="contact-content">
            <h5 className="contact-info-heading">Bank Details:</h5>
            <p>
              Account Name: Tikona Farms<br />
              Account Type: Current Account<br />
              A/c No: 492801010049200<br />
              Bank Name: Union Bank of India, Kolvan Branch<br />
              IFSC Code: UBIN0549282
            </p>
          </div>
        </div>

        <div className="contact-section">
          <h2 id="head2">Find us on Google Maps</h2>
          <br />
          <div className="google-maps-container">
            <iframe
              className="google-maps-iframe"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15124.930731471404!2d73.5190041!3d18.6086012!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2a64ebad842f5%3A0xfa8ce7bfe8fdf318!2sTIKONA%20FARMS-%20Memorable%20Lakeside%20Tourism!5e0!3m2!1sen!2sin!4v1682015827964!5m2!1sen!2sin"
              width="100%"
              height="250"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Tikona Farms GoogleMap"
            />
          </div>
        </div>
      </div>
    </div>
  );
}