import React, { useRef } from "react";
import "./Home.css";
import Images from "../Facilities/Images";
import { useScrollAnimation } from "../useScrollAnimation";

export default function Offers() {

  const paraRef = useRef();
  const showText = useScrollAnimation(paraRef);

  let offers = [
    {
      src:"./Photos/DayOuting-logo.webp",
      alt:"Day Return Outing",
      name:"Day Return Outing",
    },
    {
      src:"./Photos/tent1-logo.webp",
      alt:"Cottage",
      name:"Lakeside Cozy Room Stay",
    },
    {
      src:"./Photos/Bonfire.webp",
      alt:"Bonfire & BBQ",
      name:"Lakeside Camping with Bonfire & Live BBQ",
    },
    {
      src:"./Photos/Facilities/Pottery-logo.webp",
      alt:"Pottery",
      name:"Pottery",
    },
    {
      src:"./Photos/Photogallery/fishing2-logo.webp",
      alt:"Fishing",
      name:"Fishing",
    },
    {
      src:"./Photos/pottery-logo.webp",
      alt:"Corporate Outings & Team Building Activities",
      name:"Corporate Outings & Team Building Activities",
    },
  ];

  return (
    <>
    <div className="what">
      <div className="nav-item">
          <h2 id="head2">What we Offer</h2>
      </div>
      <p id="para" ref={paraRef} className={showText[0] ? "animated" : ""}>
        Indulge in Nature's Delights: Unveiling the Offerings of Tikona Farms<br/>
        At Tikona Farms, we take pride in offering a delectable spread of Maharashtrian Gavran vegetarian and non-vegetarian meals against the picturesque backdrop of a stunning lake.<br/>
        Tikona Farms offers a myriad of experiences that leave you with cherished memories and a renewed connection to the natural world.
      </p>
      <div className="row">
        {
          offers.map((image, index) => {
            return <Images src={image.src} alt={image.alt} name={image.name} key={index} />
          })
        }
      </div>
    </div>
    </>
  );
}