import React from 'react'
import './Footer.css'

export default function Footer() {
return (
    <>
    <link 
    rel="stylesheet" 
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
    <footer className="footer">
        <p>Follow us on:</p>
        <div className="social-media-links">
            <a className="btn btn-icon btn-facebook" href="https://www.facebook.com/Tikonafarms/" target="_blank" rel="noreferrer" aria-label="Visit our Facebook page">
                <i className="fa fa-facebook" />
            </a>
            &nbsp;
            <a className="btn btn-icon btn-instagram" href="https://www.instagram.com/tikona_farms/" target="_blank" rel="noreferrer" aria-label="Visit our Instagram page">
                <i className="fa fa-instagram" alt="Instagram" />
            </a>
            &nbsp;
            <a className="btn btn-icon btn-tripadvisor" href="https://www.tripadvisor.in/Restaurant_Review-g297654-d7372124-Reviews-Tikona_Picnic-Pune_Pune_District_Maharashtra.html?m=19905" target="_blank" rel="noreferrer" aria-label="Visit our Tripadvisor page">
                <i className="fa fa-tripadvisor" alt="Tripadvisor" />
            </a>
            &nbsp;
            <img src="./Photos/MTDC Logo.webp" alt="MTDC Logo" height="77px" width="77px" />
            &nbsp;
            <img src="./Photos/MART Logo.webp" alt="MART Logo" height="77px" width="60px" />
            &nbsp;
        </div>
        <div className="footer_bottom">
            <p>&copy; { new Date().getFullYear() } Tikona Farms - Memorable Lakeside Tourism</p>
        </div>
    </footer>
    </>
    )
}
