import React, { useRef } from "react";
import "./Facilities.css";
import Images from "./Images";
import { useScrollAnimation } from "../useScrollAnimation";

const MemorizedImages = React.memo(Images);

export default function Facilities() {

  const paraRef = useRef();
  const showText = useScrollAnimation(paraRef);

  let facilties = [
    {
      src:"./Photos/Facilities/Non-Veg-logo.webp",
      alt:"Gavran Meal",
      name:"Indulge in Delectable Gavran Vegetarian and Non-Vegetarian Meal",
    },
    {
      src:"./Photos/Facilities/Riverfront.webp",
      alt:"Lakefront Seating",
      name:"Relish the Serene Lakefront Seating Area",
    },
    {
      src:"./Photos/Photogallery/obstacle.webp",
      alt:"Playground Area",
      name:"Let Your Kids Play in the Dedicated Playground Area",
    },
    {
      src:"./Photos/Photogallery/site23-logo.webp",
      alt:"Nursery Area",
      name:"Explore the Nursey Area with Lush Plantations",
    },
    {
      src:"./Photos/Facilities/Hammock.webp",
      alt:"Hammocks",
      name:"Swing by the Lake. Hammocks Await!",
    },
    {
      src:"./Photos/Facilities/Parking-logo.webp",
      alt:"Car Parking",
      name:"Convenient Car & Bus Parking Available",
    },
    {
      src:"./Photos/Facilities/Aid-logo.webp",
      alt:"First Aid",
      name:"Stay safe at Tikona Farms with on-site First Aid",
    },
    {
      src:"./Photos/Facilities/Backup-logo.webp",
      alt:"Backup Power",
      name:"Never worry about Power Outages at Tikona Farms",
    },
    {
      src:"./Photos/Facilities/Solar-logo.webp",
      alt:"Solar Water Heater",
      name:"Enjoy Abundant Hot Water with Solar Heaters",
    },
    {
      src:"./Photos/Facilities/Barbeque-logo.webp",
      alt:"Barbeque",
      name:"Indulge in a Mouthwatering Barbecue",
    },
    {
      src:"./Photos/Cottage1.webp",
      alt:"Cosy Room",
      name:"Unwind in Cosy Rooms for Relaxation",
    },
  ];

  let activities = [
    {
      src:"./Photos/Facilities/PlayArea-logo.webp",
      alt:"Play Area",
      name:"Kids Area: Unleash your wild imagination, kids!",
    },
    {
      src:"./Photos/Photogallery/activity2-logo.webp",
      alt:"Obstacle Games",
      name:"Obstacles Games: Challenges Await!",
    },
    {
      src:"./Photos/Photogallery/zipline1.webp",
      alt:"Zipline",
      name:"Mini Zipline for Kids",
    },
    {
      src:"./Photos/Facilities/Archery-logo.webp",
      alt:"Archery",
      name:"Archery: Hit the bullseye at Tikona",
    },
    {
      src:"./Photos/Facilities/Cricket-logo.webp",
      alt:"Cricket",
      name:"Unleash your cricketing spirit outdoors!",
    },
    {
      src:"./Photos/Facilities/Volleyball-logo.webp",
      alt:"Volleyball",
      name:"Volleyball Fun",
    },
    {
      src:"./Photos/Facilities/Pottery-logo.webp",
      alt:"Pottery",
      name:"Pottery: Handcrafted Elegance at Tikona Farms",
    },
    {
      src:"./Photos/Facilities/TableTennis-logo.webp",
      alt:"Table Tennis",
      name:"Table Tennis: Spin, smash, rally at Tikona!",
    },
    {
      src:"./Photos/Facilities/Chess-logo.webp",
      alt:"Chess",
      name:"Chess: Master your moves at Tikona",
    },
    {
      src:"./Photos/Facilities/Carrom-logo.webp",
      alt:"Carrom",
      name:"Carrom: Flick. Strike. Win",
    },
    {
      src:"./Photos/Facilities/Foosball-logo.webp",
      alt:"Foosball",
      name:"Foosball: Unleash Your Competitive Spirit",
    },
    {
      src:"./Photos/Facilities/Trampoline-logo.webp",
      alt:"Trampoline",
      name:"Trampoline: Bounce into fun at Tikona!",
    },
    {
      src:"./Photos/Facilities/Trek-logo.webp",
      alt:"Tikona Trek",
      name:"Tikona Fort Trek",
    },
    {
      src:"./Photos/Facilities/TeamBuilding-logo.webp",
      alt:"Corporate Events",
      name:"Corporate Events with Team Building Activities",
    },
    {
      src:"./Photos/Facilities/Bird-logo.webp",
      alt:"Bird Watching",
      name:"Bird Watching: Discover birds at Tikona Farms",
    },
    {
      src:"./Photos/Facilities/Camping.webp",
      alt:"Camping",
      name:"Camping: Explore. Unwind. Adventure.",
    },
    {
      src:"./Photos/boat1.webp",
      alt:"Boating",
      name:"Boating (Not Owned By Tikona Farms)",
    },
    {
      src:"./Photos/Facilities/Fishing-logo.webp",
      alt:"Fishing",
      name:"Fishing (Subject To Availability)",
    },
  ];

  let seasonal = [
    {
      src:"./Photos/Facilities/Fireflies-logo.webp",
      alt:"Fireflies",
      name:"Fireflies (1st Week of June)",
    },
    {
      src:"./Photos/Facilities/Crab.webp",
      alt:"Crab Hunting",
      name:"Crab Hunting Adventure awaits!",
    },
    
    {
      src:"./Photos/Photogallery/ricefarming1-logo.webp",
      alt:"Rice Farming",
      name:"Witness Rice Farm Plantations in Full Bloom",
    },
    {
      src:"./Photos/Facilities/LiveMusic-logo.webp",
      alt:"Live Music",
      name:"Live Music on Weekend in Winter",
    },
    {
      src:"./Photos/Photogallery/christ11-logo.webp",
      alt:"Christmas",
      name:"Magic Show & Santa Claus for Christmas",
    },
    {
      src:"./Photos/Facilities/NewYear.webp",
      alt:"New Year",
      name:"Unforgettable New Year at Tikona!",
    },
    {
      src:"./Photos/Facilities/Kite-logo.webp",
      alt:"Kite Flying",
      name:"Kite flying: Soaring joy amidst picturesque fields",
    },
    {
      src:"./Photos/Facilities/Mango-logo.webp",
      alt:"Mango Plucking",
      name:"Delight in the Presence of Alphonso Mango Trees",
    },
  ];
  
  return (
    <div className="Facilities">
      <div className="Fac-Top">
        <div className="aboutContainer">
          <h2 id="head1">Tikona Farms: Explore Exceptional Facilities for Unforgettable Experiences</h2>
        </div>
        <div className="Header"></div>
        <p id="para" ref={paraRef} className={showText[0] ? "animated" : ""} style={{"marginTop":"10px"}}>
          Indulge in Delightful Gavran Veg and Non-Veg Meals at Tikona Farms and Camping<br />
          Tikona Farms and Camping offer an exquisite dining experience by serving you delicious Gavran veg and non-veg meals right at the picturesque lakeside. Immerse yourself in relaxation within cozy huts, surrounded by the captivating beauty of nature. Let your children explore the wonders of nature, engage in playful activities at the playground, and enjoy thrilling rides on tractors and bullock carts. For an enchanting star-gazing adventure, embark on a camping journey that includes BBQ delights, allowing you to step into a whole new world of wonder.<br />
          Experience the Perfect Blend of Nature and Gastronomy at Tikona Farms and Camping.
        </p>
      </div>
      <div className="FacButtons">
        <a href="#facility" className="btn btn-primary custom-button">
          Explore Facilities
        </a>
        <a href="#activity" className="btn btn-primary custom-button">
          Explore Activities
        </a>
        <a href="#seasonalActivity" className="btn btn-primary custom-button">
          Explore Seasonal Activities
        </a>
      </div>
      <div className="Group" id="facility">
        <h2 id="head2">Exceptional Facilities at our Campsite</h2>
          <div className="row">
            {
              facilties.map((image, index) => {
                return <MemorizedImages src={image.src} alt={image.alt} name={image.name} key={index} />
              })
            }
          </div>
        </div>
        <div className="Group" id="activity">
            <h2 id="head2">Activities</h2>
            <div className="row">
              {
                activities.map((image, index) => {
                  return <MemorizedImages src={image.src} alt={image.alt} name={image.name} key={index} />
                })
              }
            </div>
          </div>
          <div className="Group" id="seasonalActivity">
            <h2 id="head2">Seasonal Activities</h2>
            <div className="row">
              {
                seasonal.map((image, index) => {
                  return <MemorizedImages src={image.src} alt={image.alt} name={image.name} key={index} />
                })
              }
            </div>
          </div>
    </div>
  );
}