import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

export default function Navbar() {
  const [click, setClick] = useState(false);
  const location = useLocation();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const navbarLinks = document.querySelectorAll(".nav-links");
    const navbarLogo = document.querySelector(".navbar-logo");

    navbarLinks.forEach((link) => {
      link.addEventListener("click", handleScrollToTop);
    });
    navbarLogo.addEventListener("click", handleScrollToTop);

    return () => {
      navbarLinks.forEach((link) => {
        link.removeEventListener("click", handleScrollToTop);
      });
      navbarLogo.removeEventListener("click", handleScrollToTop);
    };
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img id="Nav-logo" src="./Photos/logo.webp" alt="Logo" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="/"
                className={
                  location.pathname === "/" ? "nav-links active" : "nav-links"
                }
                onClick={closeMobileMenu}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/about"
                className={
                  location.pathname === "/about"
                    ? "nav-links active"
                    : "nav-links"
                }
                onClick={closeMobileMenu}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/facilities"
                className={
                  location.pathname === "/facilities"
                    ? "nav-links active"
                    : "nav-links"
                }
                onClick={closeMobileMenu}
              >
                Facilities & Activities
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/gallery"
                className={
                  location.pathname === "/gallery"
                    ? "nav-links active"
                    : "nav-links"
                }
                onClick={closeMobileMenu}
              >
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/tourism"
                className={
                  location.pathname === "/tourism"
                    ? "nav-links active"
                    : "nav-links"
                }
                onClick={closeMobileMenu}
              >
                Tourism
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/packages"
                className={
                  location.pathname === "/packages"
                    ? "nav-links active"
                    : "nav-links"
                }
                onClick={closeMobileMenu}
              >
                Packages
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                className={
                  location.pathname === "/contact"
                    ? "nav-links active"
                    : "nav-links"
                }
                onClick={closeMobileMenu}
              >
                Contact
              </Link>
            </li>
            <div className={click ? "social-links active" : "social-links"}>
              <a
                className="btn btn-icon btn-facebook"
                href="https://www.facebook.com/Tikonapicnicresort"
                target="_blank"
                rel="noreferrer"
                aria-label="Visit our Facebook page"
              >
                <i className="fa fa-facebook" />
              </a>
              <a
                className="btn btn-icon btn-instagram"
                href="https://www.instagram.com/tikona_farms/"
                target="_blank"
                rel="noreferrer"
                aria-label="Visit our Instagram page"
              >
                <i className="fa fa-instagram" alt="Instagram" />
              </a>
              <a
                className="btn btn-icon btn-tripadvisor"
                href="https://www.tripadvisor.in/Restaurant_Review-g297654-d7372124-Reviews-Tikona_Picnic-Pune_Pune_District_Maharashtra.html?m=19905"
                target="_blank"
                rel="noreferrer"
                aria-label="Visit our Tripadvisor page"
              >
                <i className="fa fa-tripadvisor" alt="Tripadvisor" />
              </a>
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
}