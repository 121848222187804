import React, { useRef } from "react";
import "./about.css";
import Carousel from "./Carousel";
import { useScrollAnimation } from "../useScrollAnimation";

export default function About() {

  const paraRef = useRef();
  const showText = useScrollAnimation(paraRef);

  return (
    <>
    <div className="about">
      <div className="aboutContainer">
        <h2 id="head1">Experience the Best of Nature and Agro Tourism at Tikona Farms</h2>
      </div>
      <div className="About"></div>
      <p id="para" ref={paraRef} className={showText[0] ? "animated" : ""} style={{"marginTop":"10px"}}>
        Welcome to Tikona Farms and Camping: A Family-Managed Oasis in Mulshi<br />
        Tikona Farms and Camping, under the diligent management of Suresh Marathe and his Family, is a sprawling retreat spanning 3.5 acres of lush land adorned with mango trees and a diverse range of plant life. Our farm proudly cultivates rice and various vegetables, creating a sustainable environment in harmony with nature.<br />
        Since our establishment in 2013, Tikona Farms and Camping has been dedicated to providing an exceptional experience for our guests. Indulge in delicious meals infused with local flavours, complemented by a range of engaging activities. With our capacity to host get-togethers for 100-150 people on a day package basis, we ensure a memorable time from breakfast to evening snacks.<br />
        Discover an array of exciting activities during your stay, including engaging games for children, fishing adventures in the nearby lake, bird watching opportunities, and a dedicated playground for children.<br />
        At Tikona Farms and Camping, we strive to create cherished moments for every guest. Let's create such moments for you too. Contact Now.
      </p>
      <div className="Award">
        <h2 id="head2">Achievements</h2>
        <br />
        <Carousel />
      </div>
    </div>
    </>
  );
}