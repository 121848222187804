const Images = (props) => {
    return<>
        <div className="col-sm">
            <div className="card" style={{ width: "18rem", height:"12rem" }}>
                <img className="card-img-top" src={props.src} alt={props.alt} />
            </div>
        </div>
    </>
}

export default Images