const Images = (props) => {
    return <>
        <div className="col">
            <div className="card" style={{ width: "18rem" }}>
                <img className="card-img-top" src={props.src} alt={props.alt} />
                <div className="card-body">
                    <p className="card-text">{props.name}</p>
                    <p className="card-text">
                        <a href={props.href} target="_blank" rel="noreferrer" style={{"color":"#283055"}}>Directions</a>
                    </p>
                </div>
            </div>
        </div>
    </>
}

export default Images