import React from "react";
import "./tourism.css";
import Images from "./Images";

export default function Tourism() {

  let images = [
    {
      name:"Tikona Fort",
      src:"./Photos/Facilities/Trek-logo.webp",
      alt:"Tikona Fort",
      href:"https://goo.gl/maps/sAj6gJp7HevNzeCh8",
    },
    {
      name:"Hadshi Temple",
      src:"./Photos/Tourism/HadshiTemple-logo.webp",
      alt:"Hadshi Temple",
      href:"https://goo.gl/maps/TxnzXn9vSsTCyZur5",
    },
    {
      name:"Chinmaya Vibhooti Temple",
      src:"./Photos/Tourism/ChinmayaVibhuti-logo.webp",
      alt:"Chinmaya Vibhooti",
      href:"https://goo.gl/maps/7wCzjJt7oW1wVxNZA",
    },
    {
      name:"Tung Fort",
      src:"./Photos/Tourism/TungFort-logo.webp",
      alt:"Tung Fort",
      href:"https://goo.gl/maps/j973iuSgQi1XXaUB9",
    },
    {
      name:"Lohagad Fort",
      src:"./Photos/Tourism/LohagadFort-logo.webp",
      alt:"Lohagad Fort",
      href:"https://goo.gl/maps/UX8bgKXWfVqoaq2r5",
    },
    {
      name:"Visapur Fort",
      src:"./Photos/Tourism/VisapurFort-logo.webp",
      alt:"Visapur Fort",
      href:"https://goo.gl/maps/kJx92awi9yXN8wEEA",
    },
    {
      name:"Lonavala",
      src:"./Photos/Tourism/Lonavala-logo.webp",
      alt:"Lonavala",
      href:"https://goo.gl/maps/qveqL1cs8N6KpYZJ8",
    },
    {
      name:"Pavana Lake",
      src:"./Photos/Tourism/Pawana-logo.webp",
      alt:"Pavana Lake",
      href:"https://goo.gl/maps/cg77W1tRVq887cU97",
    },
    {
      name:"Ajivali Devrai",
      src:"./Photos/Tourism/AjivaliDevrai-logo.webp",
      alt:"Ajivali Devrai",
      href:"https://goo.gl/maps/zBinUBfxaqpdECGC7",
    },
    {
      name:"Wagheshwar Temple",
      src:"./Photos/Tourism/WagheshwarTemple-logo.webp",
      alt:"Wagheshwar Temple",
      href:"https://goo.gl/maps/gscV8Pe757H2yESP7",
    },
    {
      name:"Bedse Caves",
      src:"./Photos/Tourism/BedseCaves-logo.webp",
      alt:"Bedse Caves",
      href:"https://goo.gl/maps/FdgB5XrnVnFGDiiH8",
    },
    {
      name:"Prati Pandharpur",
      src:"./Photos/Tourism/PratiPandharpur-logo.webp",
      alt:"Prati Pandharpur",
      href:"https://goo.gl/maps/yu5pnsZ2NRmTyzgr8",
    },
    {
      name:"Bhalgudi Temple",
      src:"./Photos/Tourism/BhalgudiTemple-logo.webp",
      alt:"Bhalgudi Temple",
      href:"https://goo.gl/maps/CJW6qmRqURPnChvs5",
    },
  ];

  return (
  <div className="Tourism-Container">
    <div className="aboutContainer">
      <h2 id="head1">Nearby Tourism</h2>
    </div>
    <div className="Tourism">
      <br />
      <div className="map-container">
        <img src="./Photos/Tourism/map.webp" alt="Map" className="map-image" width="1920" height="1080" />
      </div>
      <br />
        <div className="Places">
        <h2 id="head2">Places to Visit near Tikona Farms</h2>
        <div className="row">
          {
            images.map((image, index) => {
              return <Images src={image.src} alt={image.alt} name={image.name} href={image.href} key={index} />
          })}
          <div className="col">
            <div className="card" style={{ width: "18rem" }}>
                <img className="card-img-top" src="./Photos/Tourism/Paragliding-logo.webp" alt="Paragliding Sports" />
                <div className="card-body">
                    <p className="card-text">Paragliding Sports</p>
                </div>
            </div>
          </div>
          <div className="col">
            <div className="card" style={{ width: "18rem" }}>
                <img className="card-img-top" src="./Photos/Tourism/KhandobaTemple-logo.webp" alt="Local Temples" />
                <div className="card-body">
                    <p className="card-text">Many More Local Temples</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}